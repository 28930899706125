import Phaser from 'phaser'
import PlayScene from './scenes/PlayScene'

function launch(containerId, em, details) 
{    
    const game = new Phaser.Game({
        type: Phaser.AUTO,
        width: window.innerWidth,
        height: window.innerHeight,
        parent: containerId,
        transparent: true,
        physics: {
            default: "arcade"
        },
        scene: [PlayScene],
    })

    game.ninjaDetails = details
    game.registry.events = em
}

export default launch
export { launch }